<template>
    <div class="header_container">
		<!-- {{route.meta}} -->
		<a-breadcrumb separator=">">
			<a-breadcrumb-item  v-for="(item, index) in route.meta.data" :key="index">
				<router-link :to="item.url">{{item.title}}</router-link>
			</a-breadcrumb-item>
		</a-breadcrumb>
    </div>
</template>

<script>
    export default {
    	data(){
    		return {
				// baseImgPath,
				route:this.$route
    		}
    	},
    	created(){
    		// if (!this.adminInfo.id) {
    		// 	this.getAdminData()
    		// }
		},
		mounted(){
			// console.log(this.$route);
		},
    	computed: {
    		// ...mapState(['adminInfo']),
    	},
		methods: {
			goUrl(item){
				console.log(item)
				this.$route.push(item.url)
			}
		}
    }
</script>

<style lang="less">
	// @import '../style/mixin';
	.header_container{
		background-color: #f6f6f6;
		height: 60px;
		line-height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		// padding-left: 20px;
	}
</style>
